import { useState } from "react";
import { Form } from "antd";
import { withTheme } from "styled-components";
import FormConsultationSmall from "./small";
import FormConsultationLarge from "./large";
import ClassicFormConsultation from "../Consultation-Classic";
import { setGoogleAdwordsInfo } from "../../../utils/helper";
import useFetch from "../../../hooks/useFetch";
import { useCookies } from "react-cookie";
import { getQueryFromStorage } from "../../../hooks/useQueryCatcher";
import TagManager from "react-gtm-module";

const CRM_URL = "/api/contact";

const FormConsultation = ({ theme, variant, referralCode, submitButton, dispatchDestination = "contact", formId, darkLabels }) => {
  const [cookie, setCookie] = useCookies(["converted"]);
  const { submitMultiple } = useFetch();
  const [form] = Form.useForm();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values) => {
    setLoading(true);
    let url = typeof window !== "undefined" ? window.location.href : "null";
    
    const baseData = {
      ...values,
      client: 'classyclosets',
      queries: getQueryFromStorage(),
      user_id: window.uniqueVisitorId,
      visitorId: window.uniqueVisitorId,
      confidenceScore: window.confidenceScore,
      fingerprint: window.uniqueVisitorId
    };

    setGoogleAdwordsInfo(values);
    TagManager.dataLayer({
      dataLayer: {
        crmOrClassic: "classic",
      },
    });

    try {
      await submitMultiple([
        {
          url: CRM_URL,
          method: "POST",
          data: baseData
        }
      ]);

      setCookie("converted", "step_1", {
        path: "/",
        maxAge: 86400 * 7 // 7 days
      });

      setTimeout(() => {
        form.resetFields();
        setShowModal(true);
        setLoading(false);
      }, 300);
    } catch (error) {
      console.error('Form submission error:', error);
      setLoading(false);
    }
  };

  return (
    <ClassicFormConsultation
      variant={variant}
      dispatchDestination={dispatchDestination}
      submitButton={submitButton}
      formId={formId}
      referralCode={referralCode}
      darkLabels={darkLabels}
      handleSubmit={handleSubmit}
      loading={loading}
      form={form}
      showModal={showModal}
      setShowModal={setShowModal}
    />
  );
};

export default withTheme(FormConsultation);
